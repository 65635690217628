$assetPath: "https://prdraubotsvc.bp.com/assets/";
.web-chat {
    overflow: hidden;
  }


  .webchat__basic-transcript__focus-indicator,
  .webchat--css.webchat__basic-transcript
    .webchat__basic-transcript__terminator:focus
    + .webchat__basic-transcript__focus-indicator {
    border-style: none !important;
  }
  
  .webchat--css .webchat__basic-transcript:focus {
    border: none !important;
  }
  
  .webchat__basic-transcript__activity-indicator--focus {
    border: none !important;
  }
  
  .webchat__stacked-layout__main {
    border: none !important;
  }
  
  .webchat__basic-transcript {
    border: none !important;
  }
  
  header .filler {
    text-align: left !important;
    margin-top: 9px;
    text-indent: 15px;
  }
  .ac-container.ac-adaptiveCard [type="button"] {
    color: #007f00;
    text-align: right !important;
    margin: 0 !important;
    border: #007f00 1px solid;
    border-radius: 3px;
  }
  
  .style-default > div {
    white-space: pre-wrap !important;
  }
  
  .ac-textBlock ul {
    list-style: disc !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .ac-textBlock ol {
    list-style-type: decimal !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .webchat__bubble__content ol {
    list-style-type: decimal !important;
    padding-inline-start: 10px;
  }
  
  .webchat__bubble__content ul {
    list-style-type: disc !important;
    padding-inline-start: 10px;
  }
  
  .markdown {
    font-family: inherit;
    font-size: 95% !important;
    color: #666;
  }
  
  .markdown ul {
    list-style: disc !important;
    padding-inline-start: 15px;
  }
  
  .markdown ol {
    list-style-type: decimal !important;
    padding-inline-start: 15px;
  }
  

  .webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
    border-top-left-radius: 0px !important;
  }
  
  .webchat__bubble--from-user .webchat__bubble__content {
    border-top-right-radius: 0px !important;
  }
  
  .ac-pushButton {
    border: #007f00 1px solid !important;
    border-radius: 3px;
    cursor: pointer;
  }
  .ac-pushButton:hover {
    background-color: #f7f7f7 !important;
  }
  
  .webchat__adaptive-card-renderer .ac-pushButton[aria-pressed="true"] {
    background-color: #f7f7f7 !important;
    border-color: none !important;
    color: #007f00;
  }
  
  .webchat__adaptive-card-renderer .ac-pushButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: White;
    border-style: solid;
    border-width: 1px;
    color: #007833 !important;
    font-size: 75%;
    padding: 10px;
  }
  
  
  .nz .markdown ul {
    list-style: disc !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .nz .markdown ol {
    list-style-type: decimal !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .nz .ac-textBlock ul {
    list-style: disc !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .nz .ac-textBlock ol {
    list-style-type: decimal !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .nz .webchat__bubble__content ul {
    list-style-type: disc !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  .nz .webchat__bubble__content ol {
    list-style-type: decimal !important;
    list-style-position: inside !important;
    padding-inline-start: 10px;
  }
  
  div .ac-columnSet {
    border: none !important;
  }
  
  div .ac-columnSet input {
    border: 1px solid #007833 !important;
    margin-left: -70px;
    height: 40px;
    padding-left: 10px;
    border-radius: 5px;
  }
  
  div.ac-input-container {
  }
  
  .ac-pushButton[aria-label="Card delivery"] {
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 5px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Report lost/stolen card"] {
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 5px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Change Pin for a single card"] {
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 5px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Cancel card"] {
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 5px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Main Menu"] {
    background-image: url(#{$assetPath}bp-icon-menu-white.svg);
    background-repeat: no-repeat;
    background-position: 8px;
    background-size: 15px;
    color: #fff !important;
    background-color: #007f00 !important;
    text-indent: 20px;
  }
  
  .webchat__adaptive-card-renderer
    .ac-pushButton[aria-pressed="true"][aria-label="Main Menu"] {
    background-color: #004f00 !important;
    border-color: #004f00 !important;
    color: white;
  }
  
  .ac-pushButton[aria-label="Account support"] {
    background-image: url(#{$assetPath}bp-icon-people-circle-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="How do rewards work?"] {
    background-image: url(#{$assetPath}bp-icon-mobile-device-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="How do rewards work? "] {
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Missing rewards"] {
    background-image: url(#{$assetPath}bp-icon-rosette-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Rewards card queries"] {
    background-image: url(#{$assetPath}bp-icon-credit-card-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Manage Reports"] {
    background-image: url(#{$assetPath}bp-icon-finance-accrue-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="I have a specific question"] {
    background-image: url(#{$assetPath}bp-icon-circle-question-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label="Submit"] {
    background-image: url(#{$assetPath}bp-icon-tick-checklist-bp-dark-green.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    background-size: 20px;
    text-indent: 30px;
    color: #007833 !important;
  }
  
  .ac-pushButton[aria-label='Submit']:disabled{
    background-color: #ebebeb !important;
  }
  
  .ac-multiline {
    font-size: inherit;
  }
  
  .ac-input-validation-error-message {
    color: #ff6600;
    padding-top: 10px;
  }
  
  .webchat__suggested-actions__carousel {
    background-color: #f2f2f2;
    border-top: 1px #f5f5f5 solid;
  }
  
  .webchat__suggested-action__button:hover {
    background-color: #f2f2f2 !important;
    cursor: pointer;
  }
  
  .ac-pushButton div {
    text-align: left !important;
    overflow: visible !important;
    width: 100%;
  }
  
  .from-user.bubble .webchat__bubble__content p {
    color: #fff !important;
    line-height: 1rem;
  }
  

  
  .chatRestart {
    display: inline-block;
    background: url(#{$assetPath}bp-icon-clockwise-anti-white.svg) no-repeat;
    width: 22px;
    height: 22px;
  }
  
  .css-1abghdv {
    border-top: 1px #eeeeee solid !important;
    background-color: #f9f9f9;
  }
  
  
  
  .webchat__basic-transcript__activity:first-child {
    padding-top: 110px !important;
    background-image: url(#{$assetPath}logoMain.svg);
    background-repeat: no-repeat;
    background-position: center 20px;
    background-size: 20%;
  }
  
  .nz .webchat__basic-transcript__activity:first-child {
    background-image: url(#{$assetPath}logoMain-nz.svg);
  }
  
  li[role="listitem"]:first-child {
    padding-top: 175px;
    background-image: url(#{$assetPath}logoMain.svg);
    background-repeat: no-repeat;
    background-position: center 20px;
    background-size: 20%;
  }
  
  .bot-loading {
    padding-top: 175px !important;
    background-image: url(#{$assetPath}bp-loader.svg);
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 75px !important;
  }
  
  .ac-textBlock {
    white-space: normal !important;
    font-family: "UniversforBP", Arial, Helvetica, sans-serif;
    font-size: 107% !important;
  }
  
  .ac-textBlock p {
  color: #606060;
  }
  
  .ac-richTextBlock {
    padding-bottom: 5px;
    padding-top: 10px;
  }
  
  /*  Quantas and BP Rewards font size in form*/
  span.ac-textRun {
    font-size: 95% !important;
    color: #007f00!important;
  }
  
  
  
  /* Eof New Chat Bot CSS Overides [Machine Two]*/
  .webchat__bubble__content .plain {
    border: 1px #007F00 solid;
    background-color: #007F00;
    color: #fff !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  
  .container-bubble {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 74px;
    background: none;
    border: 0px;
  }
  
  .image {
    display: block;
    height: auto;
    cursor: pointer;
    background: url(#{$assetPath}pillOff.svg) no-repeat center center;
    background-size: 74px 74px;
    width: 74px;
    height: 74px;
  }
  
  .image:hover {
    background: url(#{$assetPath}pillOn.svg) no-repeat center center;
    background-size: 74px 74px;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 0;
    overflow: hidden;
    width: 0;
    height: 74px;
    transition: 0.4s ease-in-out;
    z-index: 0;
  }
  
  .container-bubble:hover .overlay, .sample .overlay {
    left: -242px;
    width: 276px;
    }
  
  .slider {
    height: 74px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  
  .ms-Icon .ms-Icon--MessageFill .ms-Icon--MessageFill:before {
    display: none !important;
  }
  
  .container-bubble:focus {
    outline: 0px !important;
  }
  
  .overlay .slider {
    top: -1px;
    left: 0;
  }
  
  .overlay {
    opacity: 1;
  }
  
  .slider {
    opacity: 0.3;
    position: relative;
  }
  
  .animation {
    position: absolute;
    height: 74px;
    top: 0;
    left: 0;
  }
  
  .container-bubble:hover .overlay {
    transform: translatex(0px);
    opacity: 1;
  }
  
  .container-bubble:hover .slider {
    transform: translatex(0px);
    opacity: 1;
  }
  
  /* Attachment and Send icons */
  
  /* Old style Attachment and Send icons 
  
  button[title='Upload file'] > svg {width: 35px!important; height: 35px!important; padding-top: 10px!important; padding-left: 10px!important}
  button[title='Send'] > svg {width: 45px!important; height: 45px!important;}
  button.switch span {font-weight: bolder!important; margin-left: 20px;}
  button.webchat__icon-button > svg {width:50px; height: 50px;transform: rotate(-45deg); position: relative; left:-10px; top:-3px; cursor: pointer;}
  .webchat__icon-button {background-color: #fff!important; background: none!important; border: 1px red solid;}
  
  /*Attahcment icon is hidden under the Z index here - to intiate remove Z-Index and margin-left attributes
  
  .webchat__send-box__main {border-bottom-right-radius: 25px; border-bottom-left-radius: 25px; background-color: #007833!important; height: 60px;}
  .webchat__send-box-text-box {height: 40px!important; margin-left: -40px; z-index: 100;}
  .webchat__send-box-text-box__input {text-indent: 10px;  border-bottom-left-radius: 20px; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-right-radius: 20px;}
  
  .webchat__upload-button--file-input {display: block!important; margin: 90px;} */
  
  .webchat__send-box__main {
    background-color: #fff !important;
    height: 60px;
    padding-left: 10px;
    padding-right: 5px;
  }
  .webchat__send-box-text-box__input {
    margin-left: 45px;
    text-indent: 10px;
    max-width: 99% !important;
  }
  
  button.switch span {
    font-weight: bolder !important;
    margin-left: 20px;
  }
  
  button.webchat__icon-button > svg {
    background-image: url(#{$assetPath}bp-icon-airplane-paper-bp-green.svg);
    background-size: 28px 28px;
    background-position: center;
    background-color: #fff;
    display: block !important;
    width: 28px !important;
    height: 28px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    margin-left: 4px !important;
    margin-top: 0px !important;
  }
  
  .webchat__send-icon {
    background-image: url(#{$assetPath}bp-icon-airplane-paper-bp-silver.svg) !important;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    width: 32px !important;
    height: 32px !important;
    position: relative;
    left: -5px;
    top: 0px;
    z-index: 0;
    fill: none !important;
    cursor: pointer;
  }
  
  /* Send icon hover */
  button[title="Send"]:hover > svg {
    background-image: url(#{$assetPath}bp-icon-airplane-paper-bp-green.svg) !important;
    background-size: 28px 28px;
    display: block !important;
    width: 28px !important;
    height: 28px !important;
    margin-left: 4px;
    margin-top: 10px;
  }
  
  
  button[title="Upload file"] > svg {
    background-image: url(#{$assetPath}bp-icon-paperclip-bp-green.svg) !important;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: center;
    background-color: #ccff00;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    fill: none !important;
    border-radius: 4px;
  }
  button[title="Upload file"] {
    z-index: 9999;
  }

  button[title="Upload file"]:hover > svg {
    background-image: url(#{$assetPath}bp-icon-paperclip-bp-green.svg) !important;
    background-size: 25px 25px;
    background-position: center;
    background-color: #c6f700;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    border-radius: 4px;
  }
  
  button[title="Upload file"]:active > svg {
    background-image: url(#{$assetPath}bp-icon-paperclip-bp-green.svg) !important;
    background-size: 25px 25px;
    background-position: center;
    background-color: none;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    border-radius: 4px;
  }
  
  button[title="Upload file"]:disabled > svg {
    background-image: url(#{$assetPath}bp-icon-paperclip-bp-silver.svg) !important;
    background-size: 25px 25px;
    background-position: center;
    background-color: #ebebeb;
    display: block !important;
    width: 35px !important;
    height: 35px !important;
    padding-top: 10px !important;
    padding-left: 10px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    border-radius: 4px;
  }
  
  .webchat__upload-button:active {
    background-color: none;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 0px;
  }
  
  .webchat__upload-button:disabled {
    background-color: #ebebeb;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 0px;
  }
  
  .webchat__upload-button {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 0px;
  }
  
  /*Attahcment icon is hidden under the Z index here - to intiate remove Z-Index and margin-left attributes*/
  .webchat__send-box-text-box {
    height: 40px !important;
    margin-left: -60px;
    z-index: 100;
    border: 0px red solid;
  }
  .webchat__send-box-text-box__input {
    text-indent: 10px;
    margin-top: 17px !important;
  }
  
  .webchat__upload-button--file-input {
    display: block !important;
    margin: 0px;
  }
  
  .webchat__upload-button--file-input {
    display: block !important;
    margin: 90px;
  }
  
  /* Rating graphics */
  
  .ac-columnSet {
    border: 1px #007f00 solid !important;
    padding-top: 12px !important;
    padding-left: 12px !important;
    padding-bottom: 15px !important;
  }
  
  .ac-input .ac-textInput .ac-multiline {
  }
  
  textarea {
    border: 1px #333 solid;
    border-radius: 8px !important;
    min-height: 100px;
    padding-left: 10px;
    padding-top: 10px;
  }
  
  
  
  #rewards_number input{
    border: 1px #333 solid;
    height: 40px;
    border-radius: 5px;
    text-indent: 5px;
  }
  
  #qantas_number input{
    border: 1px #333 solid;
    height: 40px;
    border-radius: 5px;
    text-indent: 5px;
  }
  
  #transaction_date input{
    border: 1px #333 solid;
    height: 40px;
    border-radius: 5px;
    text-indent: 5px;
  }
  
  #case_comments {
    border: 0px #007f00 solid;
  }
  .ac-input-container textarea {
    margin-left: -2px;
  }
  
  .ac-choiceSetInput-expanded {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-around;
    align-items: flex-start;
  }
  
  /* INPUT VERY EASY  */
  .ac-choiceSetInput-expanded [aria-label="Very easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Very easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  .ac-textBlock label {
    display: none !important;
  }
  
  label p {
    border: 0px red solid !important;
    display: none;
  }
  
  /* INPUT EASY  */
  .ac-choiceSetInput-expanded [aria-label="Easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-happy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-happy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  /* INPUT SOMEWHAT EASY  */
  .ac-choiceSetInput-expanded [aria-label="Somewhat easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Somewhat easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  /* INPUT NOT EASY AT ALL  */
  .ac-choiceSetInput-expanded [aria-label="Not easy at all"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Not easy at all"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-angry-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Not easy at all"]:checked {
    background: url("#{$assetPath}bp-icon-smile-angry-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  /* SECOND SURVEY RATINGS */
  
  /* INPUT VERY SATISFIED  */
  .ac-choiceSetInput-expanded [aria-label="Very satisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Very satisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very satisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  
  .ac-choiceSetInput-expanded [aria-label="Somewhat satisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat satisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-happy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat satisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-happy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  
  .ac-choiceSetInput-expanded [aria-label="Somewhat dissatisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat dissatisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat dissatisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  
  .ac-choiceSetInput-expanded [aria-label="Very dissatisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very dissatisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-angry-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very dissatisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-angry-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  
  div.ac-input-container > div > div {
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
  
  *:focus {
    outline: none;
  }
  

  .react-film__filmstrip__list {
    display: flex !important;
    flex-wrap: wrap;
  }
  
  .react-film__filmstrip__item:first-child {
    margin-left: -5px !important;
    border: 0px red solid;
  }
  
  .webchat__suggested-actions.webchat__suggested-actions--carousel-layout:not(.webchat__suggested-actions--rtl)
    .react-film__flipper
    + .react-film__filmstrip
    .react-film__filmstrip__item:first-child {
    padding-left: 5px !important;
  }
  

  
  .ac-columnSet {
    border: 1px #007f00 solid !important;
    padding-top: 12px !important;
    padding-left: 12px !important;
    padding-bottom: 15px !important;
  }
  
  .ac-input .ac-textInput .ac-multiline {
  }
  
  textarea {
    border: 1px #333 solid;
    border-radius: 8px !important;
    min-height: 100px;
    padding-left: 10px;
    padding-top: 10px;
    margin-left: 10px;
  }
  
  span.ac-textRun {
  border: 0px red solid;
  }
  
  .ac-choiceSetInput-expanded {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-around;
    align-items: flex-start;
  }
  

  .ac-choiceSetInput-expanded [aria-label="Very easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Very easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  .ac-textBlock label {
    display: none !important;
  }
  
  label p {
    border: 0px red solid !important;
    display: none;
  }
  

  .ac-choiceSetInput-expanded [aria-label="Easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-happy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-happy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  

  .ac-choiceSetInput-expanded [aria-label="Somewhat easy"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Somewhat easy"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat easy"]:checked {
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  

  .ac-choiceSetInput-expanded [aria-label="Not easy at all"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Not easy at all"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-angry-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Not easy at all"]:checked {
    background: url("#{$assetPath}bp-icon-smile-angry-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  

  .ac-choiceSetInput-expanded [aria-label="Very satisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded input[type="radio"][aria-label="Very satisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very satisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-laugh-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  .ac-choiceSetInput-expanded [aria-label="Somewhat satisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat satisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-happy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat satisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-happy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  .ac-choiceSetInput-expanded [aria-label="Somewhat dissatisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat dissatisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Somewhat dissatisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-unhappy-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  
  .ac-choiceSetInput-expanded [aria-label="Very dissatisfied"] {
    border: 0px red solid !important;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very dissatisfied"] {
    float: bottom;
    -webkit-appearance: none;
    border: none;
    height: 25px;
    width: 25px;
    padding: 25px;
    background: url("#{$assetPath}bp-icon-smile-angry-bp-silver.svg") left center
      no-repeat;
    opacity: 0.5;
  }
  
  .ac-choiceSetInput-expanded
    input[type="radio"][aria-label="Very dissatisfied"]:checked {
    background: url("#{$assetPath}bp-icon-smile-angry-bp-green.svg") left center
      no-repeat;
    padding: 25px;
    animation: popout 0.5s ease;
    -webkit-animation: popout 0.5s ease;
    opacity: 1;
  }
  @keyframes popout {
    from {
      transform: scale(0);
    }
    70% {
      transform: scale(1.2);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes popout {
    from {
      -webkit-transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1.2);
    }
    to {
      -webkit-transform: scale(1);
    }
  }
  

  div.ac-input-container > div > div {
    float: left;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
  
  *:focus {
    outline: none;
  }
  
  .webchat__suggested-actions.webchat__suggested-actions--carousel-layout:not(.webchat__suggested-actions--rtl)
    .react-film__flipper
    + .react-film__filmstrip
    .react-film__filmstrip__item:first-child {
    padding-left: 0px !important;
  }
  
  .logo-footer .logo-picture {
    z-index: 0!important;
  }
  