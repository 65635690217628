$assetPath: "https://prdraubotsvc.bp.com/assets/";
.bp-me-chat {
  width: 100vw;
  height: 100vh;
}
.minimizable-web-chat > button.maximize {
  background-image: url(#{$assetPath}pillOff.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  bottom: 20px;
  /*box-shadow: 0 0 20px rgba(0, 0, 0, .2);*/
  background-color: transparent;
  font-size: 0px;
  height: 170px;
  outline: 0;
  padding: 0px;
  position: fixed;
  right: 10px;
  width: 100px;
  border: 0px #fff solid;
}

.minimizable-web-chat > button.nz {
  background-image: url(#{$assetPath}pillOff.svg);
}

.reloadBtn {
  padding: 4px;
  width: auto;
  float: left;
  border-radius: 5px;
  background-color: #007833;
  color: #fff;
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #ef0000;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-image: url(#{$assetPath}pillOn.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  background-size: 100%;
}

.minimizable-web-chat > button.nz:hover:not(:active) {
  background-image: url(#{$assetPath}pillOn.svg);
}

.minimizable-web-chat > button.maximize:active {
  background-image: url(#{$assetPath}pillOn.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;
  background-size: 100%;
}

.minimizable-web-chat > button.nz:active {
  background-image: url(#{$assetPath}pillOn.svg);
}

.minimizable-web-chat > .chat-box {
  font-family: "UniversforBP", Arial, Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  /*  box-shadow: 0 0 20px rgba(0, 0, 0, .2);*/
  border: 4px solid #ebebeb;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  width: 30%;
  height: 75%;
  margin-bottom: 20px;
  z-index: 10000;
  font-size: inherit;
}

.minimizable-web-chat > .chat-box.hide {
  display: none;
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

.minimizable-web-chat > .chat-box > header {
  background-color: #fff;
  height: 55px;
  display: flex;
  border-bottom: 1px #ebebeb solid;
}

.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
  padding-top: 12px;
}

.minimizable-web-chat > .chat-box > header > .filler > .bot-title {
  color: #007f00;
  font-size: inherit;
  align-content: center;
  text-align: center;
  white-space: nowrap;
  padding-left: 10px;
}

.minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color: #007f00;
  outline: 0;
  margin-top: 12px;
}

.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(0, 79, 0, 0.6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(0, 79, 0, 0.6);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}
/* Bof New Chat Bot CSS Overides [Machine Two] */

#bp_logo {
  background-image: url(#{$assetPath}logoMain.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 265px 139px;
  height: 139px;
  display: block;
}
